// Description: usp-block:
// slider activated in viewport S + M

export function cvr_completion($myBlockIdentifier, url) {
       
    if (!$($myBlockIdentifier).parents('.wForm').length) {
        $($myBlockIdentifier).autocomplete({
            position: { collision: "flip" },
            source: function (request, response) {
                var urlTerm = url + request.term;
                if ($('.js-cvrcompletion-postalcode').val() !== '') {
                    urlTerm = url + $('.js-cvrcompletion-postalcode').val() + " " + request.term;
                }
                $.get(urlTerm, function (data) {
                    response($.map(data, function (item) {
                        return {
                            label: item.DisplayText,
                            value: item
                        };
                    }));
                });
            },
            focus: function checkoutAutoFill(event, ui) {
                var account = ui.item.value
                $('.js-cvrcompletion-cvr').val(account.CVR)
                $('.js-cvrcompletion-tdcid').val(account.TDCId)
                $($myBlockIdentifier).val(account.AccountName);
                //remove abide errors after added values to fils, or híglige fileds if something is wrong
                Foundation.libs.abide.validate($('form').find('.js-cvrcompletion-streetname, .js-cvrcompletion-housenumber, .js-cvrcompletion-floor, .js-cvrcompletion-doorside, .js-cvrcompletion-postalcode, .js-cvrcompletion-city, .js-cvrcompletion-TDCId, .js-cvrcompletion-companyname, .js-cvrcompletion-cvr').get(), { e: { type: 'sd' } }, false);
                event.preventDefault();
            },
            select: function checkoutAutoFill(event, ui) {
                var account = ui.item.value
                $('.js-cvrcompletion-cvr').val(account.CVR)
                $('.js-cvrcompletion-tdcid').val(account.TDCId)
                $($myBlockIdentifier).val(account.AccountName);
                //remove abide errors after added values to fils, or híglige fileds if something is wrong
                Foundation.libs.abide.validate($('form').find('.js-cvrcompletion-streetname, .js-cvrcompletion-housenumber, .js-cvrcompletion-floor, .js-cvrcompletion-doorside, .js-cvrcompletion-postalcode, .js-cvrcompletion-city, .js-cvrcompletion-TDCId, .js-cvrcompletion-companyname, .js-cvrcompletion-cvr').get(), { e: { type: 'sd' } }, false);
                event.preventDefault();
            }
        });
        $($myBlockIdentifier).on('change', function onChange() {
            if (!$(this).val()) {
                $('.js-cvrcompletion-cvr').val('')
                $('.js-cvrcompletion-tdcid').val('');
            }
        });
    } else {
        $($myBlockIdentifier).autocomplete({
            position: { collision: "flip" },
            source: function (request, response) {
                var urlTerm = url + request.term;
                if ($('.calc-postal').val() !== '') {
                    urlTerm = url + $('.calc-postal').val() + " " + request.term;
                }
                $.get(urlTerm, function (data) {
                    response($.map(data, function (item) {
                        return {
                            label: item.DisplayText,
                            value: item
                        };
                    }));
                });
            },
            focus: function checkoutAutoFill(event, ui) {
                var account = ui.item.value
                $('input[name=tfa_59]').val(account.TDCId);
                $('.calc-cvr').val(account.CVR);
                $($myBlockIdentifier).val(account.AccountName);
                event.preventDefault();
            },
            select: function checkoutAutoFill(event, ui) {
                var account = ui.item.value
                $('input[name=tfa_59]').val(account.TDCId);
                $('.calc-cvr').val(account.CVR);
                $($myBlockIdentifier).val(account.AccountName);
                event.preventDefault();
            }
        });
        $($myBlockIdentifier).on('change', function onChange() {
            if (!$(this).val()) {
                $('input[name=tfa_59]').val('');
                $('.calc-cvr').val('');
            }
        });
    }
};
    
