// Description:

export function trust_pilot($myBlockIdentifier, myCallerType) {
        $(function () {
            $myBlockIdentifier.find(".hidden").show();

            if (myCallerType === 'trust-pilot') {
                $myBlockIdentifier.find('.trustpilot-block-slider').slick({
                    dots: false,
                    arrows: true,
                    autoplay: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    autoplaySpeed: 5000,
                    prevArrow: '<a class="trust-prev"><i class="fa fa-angle-left"></i></a>',
                    nextArrow: '<a class="trust-next"><i class="fa fa-angle-right"></i></a>',

                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                                infinite: true,
                                dots: false
                            }
                        },
                        {
                            breakpoint: 640,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                infinite: true,
                                dots: true,
                                arrows: true
                            }
                        }
                    ]
                });
            }
            else { // sidebar
                $myBlockIdentifier.find('.trustpilot-block-slider').slick({
                    dots: true,
                    arrows: false,
                    autoplay: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplaySpeed: 5000,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                infinite: true,
                                dots: true
                            }
                        },
                        {
                            breakpoint: 640,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                infinite: true,
                                dots: true,

                            }
                        }
                    ]
                });
            }
        });
    }
