// Description: Adds support for simple products (directly add to basket and show lightbox)
//define('product-presentation', ['slickslider'], function () {

    var myBlockIdentifier = {}; 


 export function product_presentation($myBlockIdentifier, myProductPageUrl, isSimpleProduct) {

        let myIsSimpleProduct = (isSimpleProduct + '').toLowerCase() === 'true';
        myBlockIdentifier = $myBlockIdentifier;

        myBlockIdentifier.find('.hidden').show();

        myBlockIdentifier.find('.carousel_inner').slick({
            infinite: true,
            autoplay: true,
            arrows: false,
            dots: false,
            fade: true,
            swipe: false,
            
        });

        $(() => {
            myBlockIdentifier.find(".product-presentation-legal").on("click", function onClick() {
                $(this).closest(".product-presentation-container").find(".product-presentation-conditions").fadeIn("slow").on("click", function (event) {
                    event.stopPropagation();
                });
            });

            myBlockIdentifier.find(".modal-content__close-button").on("click", function onClick() {
                $(this).closest(".product-presentation-container").find(".product-presentation-conditions").fadeOut("fast");
            });

            myBlockIdentifier.on('click', '.product-presentation__stars', function () {
                $('body').css('overflow', 'hidden');
            });

            $(document).on('click', '.reveal-modal-bg,.close-reveal-modal', function () {
                $('body').removeAttr("style");
            });


            // Scale price font size as much as possible
            var $priceElem = myBlockIdentifier.find(".rhomb-content__price");
            $priceElem.textfill({
                widthOnly: false,
                changeLineHeight: false,
                debug: false,
                success: function () {
                    $priceElem.addClass('resized');
                },
                
                    fail: function() {
                        $priceElem.addClass('resized');
                    }
                
            });

            
        });
    }

 export function initClickhandler (myProductPageUrl, myIsSimpleProduct) {
        myBlockIdentifier.find(".buttonarea").click(function () {
            if (!myIsSimpleProduct) {
                if ($('#js-productpage-configurator').length) {
                    window.location.hash = "productpage-configurator";
                    $('html, body').animate({
                        scrollTop: $("#js-" + window.location.hash.slice(1)).offset().top
                    }, 1000);
                } else {
                    window.location.href = myProductPageUrl;
                }
            }
        });
    }
