// Description: Vehicle view for checkout flow

export function existingCustomerOverlay($myBlockIdentifier) {
        $(() => {
            var lightboxShown = false;

            var existingCustomerForm = $myBlockIdentifier[0].querySelector('.js-form-existing-customer');
            var existingCustomerFormRadioYes = existingCustomerForm.querySelector('.js-form-existing-customer-radio-yes');
            var existingCustomerFormRadioNo = existingCustomerForm.querySelector('.js-form-existing-customer-radio-no');
            var existingCustomerFormNumber = existingCustomerForm.querySelector('.js-form-existing-customer-number');

            var existingCustomerModal = $myBlockIdentifier[0].querySelector('.js-modal-existing-customer');

            $(existingCustomerModal).on('click', 'button', function onClick() {
                lightboxShown = true;

                if (this.classList.contains('js-modal-existing-customer-btn-yes')) {
                    existingCustomerFormRadioYes.checked = true;
                    existingCustomerFormNumber.classList.remove('hidden');
                    $('.js-checkout-btn-submit').removeClass('is-loading');
                }
                else {
                    existingCustomerFormRadioNo.checked = true;
                    existingCustomerFormNumber.classList.add('hidden');
                    $('#CustomerInformationForm').submit();
                }

                $(existingCustomerModal).foundation('reveal', 'close');
            });

            existingCustomerFormRadioYes.addEventListener('change', function () {
                existingCustomerFormNumber.classList.remove('hidden');
            });

            existingCustomerFormRadioNo.addEventListener('change', function () {
                existingCustomerFormNumber.classList.add('hidden');
            });

            document.addEventListener('submit', function (e) {
                if (lightboxShown === true)
                    return false;

                if (!existingCustomerForm.querySelector('input[type=radio]:checked')) {
                    e.stopPropagation();
                    e.preventDefault();

                    $(existingCustomerModal).foundation('reveal', 'open');
                    return false;
                }

                return true;
            });
        });
    }


