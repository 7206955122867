export function payment_selection_page() {
        $(() => {
            const PaymentSelection = {};

            PaymentSelection.select = ($elem) => {

                var curPanel = $elem.closest(".js-payment");
                if (curPanel.hasClass('checkout-payment-is-selected')) {
                    return; 
                }

                var activePanel = $(".js-payment.checkout-payment-is-selected");

                activePanel.removeClass("checkout-payment-is-selected");
                activePanel.find('.checkout-payment-content').slideUp(400);

                curPanel.find('.checkout-payment-content').slideDown(400, function () {
                    curPanel.addClass("checkout-payment-is-selected");
                });
            };

            $().ready(() => {

                $(".js-payment .checkout-payment-header").on("click", function onClick() {
                    var $check = $(this).find('input[type=radio]');
                    $check.prop("checked", true);

                    PaymentSelection.select($check);
                });
                    
            });     
        });
    }




