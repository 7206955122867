export function breadcrumb_popup() {
        $(".js-fa-breadcrumb__link--popup").click(function onClick() {
            var popup = $(".fa-dropdown--popup", $(this).closest(".fa-breadcrumb"));
            popup.toggleClass("fa-dropdown--is-visible");
            return false;
        });

        $("body").click(function onClick() {
            var popup = $(".fa-dropdown--popup", ".fa-breadcrumb");
            popup.removeClass("fa-dropdown--is-visible");
        });
    }