
export function custom_date_input($inputIdentifier) {
        $(() => {
            $inputIdentifier.on("keypress", function (event) {
                var regex = new RegExp("^[0-9]+$");
                var key = event.key;
                if (!regex.test(key)) {
                    event.preventDefault();
                    return false;
                }
            });

            $inputIdentifier.on("keyup", function (event) {
                var regex = new RegExp("^[0-9]+$");
                var key = event.key;
                if (regex.test(key)) {
                    var newInputValue = $(this).val().replaceAll("/", "").replaceAll('-', '');
                    if (newInputValue.length >= 2) {
                        newInputValue = newInputValue.slice(0, 2) + "-" + newInputValue.slice(2);
                    }

                    if (newInputValue.length >= 5) {
                        newInputValue = newInputValue.slice(0, 5) + "-" + newInputValue.slice(5);
                    }

                    $(this).val(newInputValue);
                }
            });

            $inputIdentifier.on("cut copy paste", function (event) {
                event.preventDefault();
            });
        });
    }
