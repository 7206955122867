// Description: Customer Information view for checkout flow
import { completeaddress } from './../common/autocomplet';

export function customer_information(myIdentifier) {
        $(function () {
            completeaddress(
                getIdByName("SearchAd", myIdentifier),
                getIdByName("AccountAddressId", myIdentifier),
                getIdByName("AccountAddressStreetName", myIdentifier),
                getIdByName("AccountAddressHouseNumber", myIdentifier),
                getIdByName("AccountAddressFloor", myIdentifier),
                getIdByName("AccountAddressDoorSide", myIdentifier),
                getIdByName("AccountAddressPostalCode", myIdentifier),
                getIdByName("AccountAddressCity", myIdentifier));

            $('.read-only').find('input, textarea, select').attr('readonly', 'readonly').attr('tabindex', '-1');
        });
    }

    // Gets the DOM element's id by its name.
    function getIdByName(name, guid) {
        const guidNoDash = guid.replace(/-/g, ''); // Remove '-'

        return $('[name="' + guidNoDash + '_' + name + '"]').prop('id');
    }