
 export function top_banner($myBlockIdentifier) {
       // $(() => {
            // Legal link open
            $myBlockIdentifier.find('.topbanner__legallink').on('click', function onClick(event) {
                $(this).closest('.topbanner').find('.topbanner-conditions').fadeIn('slow');
                event.stopPropagation();
            });

            // Legal link close
            $myBlockIdentifier.find('.modal-content__close-button').on('click', function onClick() {
                $(this).closest('.topbanner').find('.topbanner-conditions').fadeOut('fast');
            });

            // Scale price font size as much as possible
            var $priceElem = $($myBlockIdentifier).find(".topbanner__content-small__price");
            $priceElem.textfill({
                widthOnly: false,
                changeLineHeight: false,
                debug: false,
                success: function () {
                    $priceElem.addClass('resized');
                },

                fail: function () {
                    $priceElem.addClass('resized');
                }
            });
        };

 