// Description: Topbanner js includes: 
import { video_slider } from './../components/video-slider';
import { video_module } from './video-module';

export function video_carousel($myBlockIdentifier) {
        $(function () {
            video_slider($myBlockIdentifier);
            video_module($myBlockIdentifier, false);
        });
    }

