// Description: Multiple persons view for checkout flow
export function multiple_persons( maxInstances, additionalCoveredPerson, blockguid, listName, index) {
        const elSelectCustom = document.getElementsByClassName("js-selectCustom")[0];
        const elSelectCustomValue = elSelectCustom.children[0];
        const elSelectCustomOptions = elSelectCustom.children[1];
        const elMultiplePersonsContainer = document.getElementById("multiple-persons-container");

        Array.from(elSelectCustomOptions.children).forEach(function (elOption) {
          elOption.addEventListener("click", async (e) => {
            const currentOptionValue = e.target.getAttribute("data-value");

            elSelectCustomValue.textContent = e.target.textContent;
            await handleChange(currentOptionValue);

            // Close select
            elSelectCustom.classList.remove("isActive");
          });
        });

        // Toggle select on label click
        elSelectCustomValue.addEventListener("click", () => {
          elSelectCustom.classList.toggle("isActive");
        });

        // close the custom select when clicking outside.
        document.addEventListener("click", (e) => {
          const didClickedOutside = !elSelectCustom.contains(e.target);
          if (didClickedOutside) {
            elSelectCustom.classList.remove("isActive");
          }
        });

        let counter = index;
        const createPersonUrl = `${document.documentURI}CreateNewPersons`;
        const newPersonHtml = '<fieldset class="checkout-form-fieldset checkout-form-fieldset-new-person js-new-person"><div class="row"><div class="column medium-4 large-3"></div></div><h3 class="checkout-form-legend">' + additionalCoveredPerson + '</h3>';

        async function handleChange(currentOptionValue) {
            let changeCount = currentOptionValue - counter;
            changeCount++;

            if(changeCount > 0){
              await addPerson(changeCount)
            }

            for(let i = 0; changeCount +i< 0; i++){
              removePerson();
            }
        }

        async function addPerson(changeCount) {
            if (maxInstances + 1 < counter) {
                return;
            }

            const endIndex = counter + changeCount;

            await fetch(`${createPersonUrl}?guid=${blockguid}&name=${listName}&startIndex=${counter}&endIndex=${endIndex}`)
                .then((response) => { return response.text(); })
                .then(partialView => {
                 
                  const views = JSON.parse(partialView);
                  views.map(addPersonElemnt)
                  
                  counter += changeCount;
            });
        };


        function addPersonElemnt(partialView){
          const fragment = document.createRange().createContextualFragment(newPersonHtml + partialView + '</fieldset>');
          elMultiplePersonsContainer.append(fragment);
        }


        function removePerson() {
            const lastPersonElem = getLastAdditionalPerson();
            lastPersonElem.remove();
            counter--;
        }

        function getLastAdditionalPerson(){
            const addionalPersons = document.getElementsByClassName('js-new-person');
            return addionalPersons[addionalPersons.length - 1];
        }
    }
