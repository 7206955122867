// Description: 
export function product_feature($myBlockIdentifier, myHasProductPageHandler, myProductPageHandlerUrl) {

    myHasProductPageHandler = (myHasProductPageHandler + '').toLowerCase() === 'false';

    if (myHasProductPageHandler) {
        $myBlockIdentifier.find(".buttonarea").click(function () {
            window.location.href = myProductPageHandlerUrl;
        });
    }
    
    $myBlockIdentifier.find(".productfeatureblock__legallink").on("click", function onClick() {
        $(this).closest(".teaser-grid__content-right").find(".productfeatureblock-conditions").fadeIn("slow").on("click", function (event) {
            event.stopPropagation();
        });
    });
    
    $myBlockIdentifier.find(".modal-content__close-button").on("click", function onClick() {
        $(this).closest(".teaser-grid__content-right").find(".productfeatureblock-conditions").fadeOut("fast");
    });
};
