export function quantity_counter($myBlockIdentifier, minQuantity, maxQuantity) {
        $(() => {

            $($myBlockIdentifier.find(".quantity_counter_minus")).on("click", function () {
                var hdnInput = $($myBlockIdentifier).find(".hdnQuantity");
                var value = parseInt(hdnInput.val(), 10);
                if (value > 0 && value > minQuantity) {
                    hdnInput.val(value - 1);
                    $($myBlockIdentifier).submit();
                }
            });

            $($myBlockIdentifier.find(".quantity_counter_plus")).on("click", function onClick() {
                var hdnInput = $(this).parent().find(".hdnQuantity");
                var value = parseInt(hdnInput.val(), 10);
                if (value < maxQuantity) {
                    hdnInput.val(value + 1);
                    $($myBlockIdentifier).submit();
                }
            });
        });
    }