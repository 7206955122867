// Description: Search page
import { completsugestionsBody } from '../../common/autocomplet.js';


// define('search-page', ['autocomplet', 'slickslider'], function (autocomplete) {
//     function init($myBlockIdentifier, myPerformSearchWhenReady) {
//         return new _search_page($myBlockIdentifier, myPerformSearchWhenReady);
//     }

export function search_page($myBlockIdentifier, myPerformSearchWhenReady) {
        $(() => {
            var $searchbar = $myBlockIdentifier.find('.body-searchbox');
            var $searchInput = $searchbar.find("[type='search']");
            var $searchInputReset = $searchbar.find('.searchbox__reset');

            if (myPerformSearchWhenReady.toLowerCase() !== "false") {
                $('#searchform').submit();
            } else {
                // Hide the reset icon when there is no text in the input field initially
                $searchInputReset.hide();
            }

            // Activate autocompletion
            completsugestionsBody('searchbox__input__body');

            // Reset icon handler
            $searchInputReset.on('click', function (e) {
                $searchInputReset.hide();
                $searchInput.val('').focus();

                $myBlockIdentifier.find('.searchResults').html('');

                e.preventDefault();
            });

            // Graphically activate the search icon in the header and disable clicking it
            var $searchIcon = $('.js-search-icon');
            $searchIcon.addClass('active');
            $searchIcon.attr('style', 'pointer-events: none;');

            // Show/hide reset icon on text change
            $searchInput.on('input', function onInput() {
                var inputfield = this.value;
                if (inputfield !== "") {
                    $searchInputReset.show();
                } else {
                    $searchInputReset.hide();
                }
            });

            // History
            if (window.history.state && window.history.state.query) {
                $searchInput.val(window.history.state.query);
                $('#searchform').submit();
            }
            if (window.history.state && window.history.state.tab) {
                let myInterval = setInterval(() => {
                    if ($myBlockIdentifier.find('.searchResults .active').length) {
                        $('.searchResults .tabs a').each(function onClick() {
                            if (window.history.state.tab === $.trim($(this).text().toLowerCase())) {
                                $(this).click();
                            }
                        });
                        clearInterval(myInterval);
                    }
                }, 500);
            }

            $('.searchResults').on('click', '.tabs a', function onClick() {
                setHistoryState($searchInput.val(), $.trim($(this).text()).toLowerCase());
            });

            $('#searchform').on('submit', function () {
                setHistoryState($searchInput.val(), $.trim($myBlockIdentifier.find('.tabs li:first-child a').text()).toLowerCase());
            });

            function setHistoryState(query, tab) {
                var state = {
                    'query': query,
                    'tab': tab
                };

                var parms = '?' + $.param(state);

                if (window.history.state && window.history.state.query) {
                    window.history.replaceState(state, '', parms);
                } else {
                    window.history.pushState(state, '', parms);
                }
            }

            // Init tabs
            $myBlockIdentifier.on('click', '.tab-title a', function (e) {
                e.preventDefault();

                const tabId = $(this).attr('aria-controls');
                const tabToActivate = $myBlockIdentifier.find(tabId);

                $(this).parent().addClass('active').siblings().removeClass('active');
                tabToActivate.addClass('active').siblings().removeClass('active');
            });

            // Init tab slider
            $myBlockIdentifier.on('click', '.tab-slider a', function (e) {
                e.preventDefault();
                const slideEl = $(e.target).closest('.slick-slide');
                const slideIndex = parseInt(slideEl.attr('data-slick-index'));
                const tabId = $(this).attr('aria-controls');
                const tabToActivate = $myBlockIdentifier.find(tabId);

                // Slide to tab
                $('.search-results-tabs .tab-slider').slick('slickGoTo', slideIndex)

                // Activate tab
                $myBlockIdentifier.find('.tab-slider a').removeClass('active');
                $myBlockIdentifier.find(`[aria-controls="${tabId}"]`).addClass('active');

                // Activate tab content
                tabToActivate.addClass('active').siblings().removeClass('active');
            });



            // Create mutation observer to initialize carousel when search results appear
            const targetNode = document.getElementById('searchResults');
            const config = { childList: true, characterDataOldValue: true, subtree: true, characterData: true, oldValue: true };

            const callback = function(mutationsList, observer) {
                for(const mutation of mutationsList) {
                    if (mutation.type === 'childList' && mutation.addedNodes.length) {
                        $('.search-results-tabs .tab-slider:not(.slick-initialized)').slick({
                            infinite: true,
                            dots: false,
                            arrows: false,
                            autoplay: false,
                            centerMode: true,
                            variableWidth:true,
                            slidesToShow: 1,
                        });
                    }
                }
            };

            const observer = new MutationObserver(callback);
            observer.observe(targetNode, config);
        });
    }


