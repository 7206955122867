// Description: agreement number view for checkout flow
export function agreement_number() {
        $(function () {

			var form = $('#productInformationForm');
			form.attr('data-abide', '');
			$(document).foundation({
				abide: {
					live_validate: false,
					validate_on_blur: true
				}
			}, 'reflow');

            var inputField = $('.agreementnumber input');
			inputField.on('input', function () {
				var $parent = inputField.parent();
				$parent.removeClass('error');
				$parent.removeAttr('role');
				$parent.parent().removeClass('error');
			});
        });
    }