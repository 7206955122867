// Description: Counter
export function countdown($myBlockIdentifier) {

        function getTimeRemaining(endtime) {
            var t = Date.parse(endtime) - Date.parse(new Date());
            var seconds = Math.floor((t / 1000) % 60);
            var minutes = Math.floor((t / 1000 / 60) % 60);
            var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
            var days = Math.floor(t / (1000 * 60 * 60 * 24));
            return {
                'total': t,
                'days': days,
                'hours': hours,
                'minutes': minutes,
                'seconds': seconds
            };
        }

        function initializeCountdown(countdown, endtime) {
            var daysSpan = countdown.querySelector('.js-countdown-days');
            var hoursSpan = countdown.querySelector('.js-countdown-hours');
            var minutesSpan = countdown.querySelector('.js-countdown-minutes');
            var secondsSpan = countdown.querySelector('.js-countdown-seconds');
            var timeinterval = setInterval(updateCountdown, 1000);

            function updateCountdown() {
                var t = getTimeRemaining(endtime);

                daysSpan.innerHTML = t.days;
                hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
                minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
                secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

                if (t.total <= 0) {
                    $myBlockIdentifier.style.display = 'none';
                    clearInterval(timeinterval);
                }
            }

            updateCountdown();
            $myBlockIdentifier.classList.add('is-active');
        }

        var countdown = $myBlockIdentifier.querySelector('.js-countdown');
        var deadlineString = countdown.getAttribute('data-countdown-deadline');

        if (!deadlineString) {
            $myBlockIdentifier.style.display = 'none';
            return;
        }

        // Split timestamp into [ Y, M, D, h, m, s ]
        var deadlineSplit = deadlineString.split(/[- :]/);
        // Apply each element to the Date function
        var deadline = new Date(Date.UTC(deadlineSplit[2], deadlineSplit[1] - 1, deadlineSplit[0], deadlineSplit[3] || 0, deadlineSplit[4] || 0, deadlineSplit[5] || 0));

        initializeCountdown(countdown, deadline);

    };
