import { completecity } from './../common/autocomplet';

export function zipcode_address($myBlockIdentifier, myInstalledAtContactAddress) {
        $(() => {

            myInstalledAtContactAddress = ((myInstalledAtContactAddress + '').toLowerCase() === 'true');
            const myIdentifier = $myBlockIdentifier.prop('id').replace(/-/g, '');

            completecity(
                $('[name="' + myIdentifier + '_PostalCode"]').prop('id'),
                $('[name="' + myIdentifier + '_City"]').prop('id'));
            
            // If alarm is to be installed on contact address, set radio to true and hide address input.
            if (myInstalledAtContactAddress) {
                $myBlockIdentifier.find('.installed-at-contact[value="true"]').prop('checked', true);
                $myBlockIdentifier.find(".address_anotheraddress").hide();
            }

            $myBlockIdentifier.on('change', '.installed-at-contact', function onClick() {
                if (this.value === "true") {
                    $myBlockIdentifier.find(".address_anotheraddress").hide();
                }
                else {
                    $myBlockIdentifier.find(".address_anotheraddress").show();
                }
            });

            $('.read-only').find('input, textarea, select').attr('readonly', 'readonly');
        });
    }