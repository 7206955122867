////////////////////////////////////////////////////////
// ================================================== //
// Description: License Plate Selector                //
// ================================================== //
////////////////////////////////////////////////////////

 export function license_plate_selector(inputId, licensePlates) {
    const oldLicensePlateInput = document.querySelector(`#${inputId}`);
    // nothing to prefill or inputfield already populated
    if (!licensePlates || oldLicensePlateInput?.value) return
    // Only one licensePlate should just set value and not create <select>
    if (licensePlates.length === 1) {
        oldLicensePlateInput.value = licensePlates[0];
        return;
    }
    // Create <select> html with options
    const licensePlatesDropDownHtml = [`
        <select 
            aria-required="true"
            type="text"
            id="${inputId}" 
            name="${inputId}" 
            maxlength="7" 
            title="Tidligere registreringsnummer" 
            class="required" 
            style="
                height: 41px; 
                border: gray 1px solid; 
                border-radius: 4px; 
                background-repeat: no-repeat; 
                background-position: 100% center;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+');
            "
        >`];
    for (const element of licensePlates) {
        licensePlatesDropDownHtml.push('<option value="' + element + '">' + element + '</option>');
    }
    licensePlatesDropDownHtml.push('</select>');
    const licensePlateDropDown = $(licensePlatesDropDownHtml.join(''));
    $(licensePlateDropDown).insertAfter(oldLicensePlateInput);
    $(oldLicensePlateInput).remove();
}

 