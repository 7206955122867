// Description: usp-block:
// slider activated in viewport S + M

export function usp_slim($myBlockIdentifier) {
        $(function () {

            $myBlockIdentifier.find('.usp-module')
                .removeClass("hide")
                .slick({
                    mobileFirst: true,
                    dots: true,
                    arrows: false,
                    autoplay: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    responsive: [
                        {
                            breakpoint: 1023,
                            settings: {
                                slidesToShow: 3
                            }
                        }
                    ]
                });
        });
    }
