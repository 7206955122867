// Description: Trustpilot summary handling.

export function trust_summary($myBlockIdentifier) {
     $(() => {
         $myBlockIdentifier.find('.toggle-footer-links').on("click", function onClick(e) {
             $(this).toggleClass("is-closed").parent().next(".toggle-links").toggle(300);
             e.preventDefault();
         });
     });
 };
