// Description: Video module

export function video_module($myBlockIdentifier, myIsMobile) {
        var isMobile = ((myIsMobile + '').toLowerCase() === 'true');

        $(() => {
            // Event handler for when play is clicked (before overlay).
            $myBlockIdentifier.find('.video-module').on('click', function onClick() {
                var id = $myBlockIdentifier.find('.video-modal').data('video-id');

                if (id !== undefined) { // If in a video overlay context
                    $myBlockIdentifier.find('#video-modal-' + id).foundation('reveal', 'open'); // Show overlay
                }
                else { // If in a video on page
                    $myBlockIdentifier.find('.video-module').hide();
                    $myBlockIdentifier.find('.video-container').show();
                    var iframe = $(this).parent().find('iframe')[0];

                    iframe.contentWindow.postMessage(JSON.stringify({'event': 'command', 'func': 'playVideo', 'args': []}), "*");
                }
            });

            // Event handler for when module is open.
            // We need to listen on $(document), or else event is not received.
            $(document).on('opened.fndtn.reveal', '[data-reveal]', function onOpened() {
                // Filter those events not related.
                if (!$(this).hasClass('video-modal-' + $myBlockIdentifier.prop('id'))) {
                    return;
                }

                var videoId = $(this).data('video-id');
                if (videoId !== undefined) {
                    var iframe = $(this).find('iframe')[0];

                    // Only autoplay video on large-up
                    if (!isMobile) {
                        setTimeout(function () {
                            iframe.contentWindow.postMessage(JSON.stringify({'event': 'command', 'func': 'playVideo', 'args': []}), "*");
                        }, 250); // Needs a delay to work.
                    }
                }
            });

            // Event handler for when module is closing.
            // We need to listen on $(document), or else event is not received.
            $(document).on('close.fndtn.reveal', '[data-reveal]', function onClose() {
                // Filter those events not related.
                if (!$(this).hasClass('video-modal-' + $myBlockIdentifier.prop('id'))) {
                    return;
                }

                var videoId = $(this).data('video-id');
                if (videoId !== undefined) {
                    var iframe = $(this).find('iframe')[0];
                    iframe.contentWindow.postMessage(JSON.stringify({'event': 'command','func': 'stopVideo','args': []}), "*");
                }
            });
        });
    }
